body {
  margin: 0;
  background-color: black;
  color: "red";
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a, a:hover, a:visited, a:active {
  color: inherit;
  text-decoration: none;
 }

 .test {
   background-color: red;
 }

 .outline {
  text-shadow:
  3px 3px 0 #000,
  -3px 3px 0 #000,
  -3px -3px 0 #000,
  3px -3px 0 #000;
}
 
.main {
  margin-left: 400px;
  margin-right: 50px;
  min-height: 70vh;
}

.menu {
  position: fixed;
  margin-left: 20px;
  overflow: scroll;
  max-height: 70vh;
  border-bottom: 4px dashed white;
  border-top: 4px dashed white;
  padding-top: 10px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.menu::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.menu {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}



@media screen and (max-width: 700px) {
  .menu {
    position: relative;
    margin-bottom: 50px;
  }

  .main {
    margin-left: 20px;
    margin-right:20px;
  }
}

.wrapperHeader {
  max-width: 100%;
  overflow: hidden;
  height: 24px;
}

.marquee {
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  animation: marquee 90s linear infinite;
  cursor: pointer;
}

.marquee div {
  display: inline-block;
  color: white;
}

@keyframes marquee {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(-50%, 0, 0);
  }
}

.marquee:hover  {
  animation-play-state: paused;
}